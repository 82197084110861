<template>
  <div>Logout</div>
</template>
<script>
export default {
  name: 'Logout',
  mounted() {
    this.$store.dispatch("auth/logout", this.model).then(
        () => {
          this.$router.push("/dashboard");
        },
        (error) => {
          this.loading = false;
          this.message =
              (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
              error.message ||
              error.toString()
        }
    )
  }
}
</script>
